<template>
  <div>
    <slot name="table-header"></slot>
    <table-content
      :per-page="page_condition.pageSize"
      :per-page-dropdown-enabled="true"
      @perPageChange="perPageChange"
      :columns="columns"
      :rows="rows"
      :total-rows="total_rows"
      :current-page="current_page"
      :action-options="table_actions"
      @runTableAction="runTableAction"
      @sortChange="sortChange"
      @pageChange="pageChange"
    >
    </table-content>
  </div>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'

export default {
  name: 'TabOximetry',
  components: {
    TableHeader,
    TableContent,
  },
  props: {
    isShowDetailCols: {
      type: Boolean,
      default: false,
    },
    search_condition: {
      type: Object,
      default: null,
    },
    search_content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      table_actions: [],
    }
  },

  computed: {
    columns() {
      const origin = [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'member.chineseName',
          field: 'memberName',
          width: '180px',
          changeHTML: function (value, row) {
            let avatar
            if (row.memberPhoto) {
              avatar = common.getServerUrl() + row.memberPhoto
            } else {
              avatar = require('@/assets/images/user.png')
            }
            return `
              <div class="d-flex align-items-center">
                <div class="b-avatar badge-secondary rounded-circle default-photo-bg mr-05">
                  <span class="b-avatar-img">
                    <img src="${avatar}" alt="">
                  </span>
                </div>
                <div>${value}</div>
              </div>
            `
          }
        },
        {
          label: 'kiosk.measure_time',
          field: 'measureTime',
          width: '190px',
          type: 'number',
          change: this.changeTimeFormat,
        },
        {
          label: this.$t('kiosk.oximetry'),
          field: 'spO2',
          width: '120px',
          type: 'number',
          change: (val) => `${val}%`
        },
        {
          label: 'common.floor',
          field: 'floorName',
          colType: 'detail',
          width: '110px',
        },
        {
          label: 'common.room',
          field: 'roomName',
          colType: 'detail',
          width: '110px',
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          colType: 'detail',
          width: '140px',
        },
        {
          label: 'kiosk.device_name',
          field: 'kioskName',
          colType: 'detail',
          width: '200px',
        },
        {
          label: 'kiosk.reference_code',
          field: 'referenceCode',
          colType: 'detail',
          // width: '150px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
        {
          label: '',
          field: '',
          colType: 'blank',
          sortable: false,
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
      ]
      return this.isShowDetailCols
        ? origin.filter(col => col.colType !== 'blank')
        : origin.filter(col => col.colType !== 'detail')
    },
  },

  mounted() {
    if(this.rows?.length === 0) {
      this.$emit('runSearch', this, true)
    }
  },

  methods: {
    getList(condition = null, resetPage = false) {
      if (resetPage) {
        this.current_page = 1
        this.page_condition.pageIndex = 0
      }
      this.$requestWehealth({
        method: 'get',
        url: '/kioskmanage/GetMeasureSpO2List',
        params: Object.assign(
          { searchContent: this.search_content },
          this.search_condition,
          this.sort_condition,
          this.page_condition,
          condition,
        )
      })
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.measureSpO2Items
        })
    },

    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    runTableAction() {
    },
    changeTimeFormat(time) {
      if (time === null) {
        return ''
      } else {
        return this.$moment(time)
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
  }
}
</script>

<style scoped>

</style>
